import React from 'react';
import AllIcons from '../../../Utility/IconLinks';
import Button from '../../Button/Button';
import "./ExamStartModal.scss";

const ExamStartModal = ({setOpenModal, RedirectToExam}) => {
    return (
        <div className="ExamStartModal">
            <div className={"ExamStartModal-Container"}>
                <div className="ExamsModal-CloseBtn">
                    <button
                        onClick={() => {
                            setOpenModal(false);
                        }}
                    >X
                    </button>
                </div>
                <div className="ModalBody-Sec">
                    <div className="modalinc-item">
                        <img src="https://cdn.upsure.io/POS/common/exams_modal_img.svg" alt="image" width="100%" height="100%" />
                    </div>
                    <div className="modal-title">Basics of Health Insurance</div>
                    <div className="qust-time-sec">
                        <div className="quest-item">
                            <h4>20</h4>
                            <p>Question</p>
                        </div>
                        <div className="linitem"></div>
                        <div className="quest-item">
                            <h4>20</h4>
                            <p>Minutes</p>
                        </div>
                    </div>
                    <div className="instr-sec">
                        <h5>Instructions sec</h5>
                        <ol>
                            <li>
                                1.0 mark is awarded for correct attempts and 0.0 marks for
                                incorrect attempts
                            </li>
                            <li>Tap on options to select the correct answer</li>
                        </ol>
                    </div>
                    <div className="btnsec">
                        <Button
                            label={"Start Exam"}
                            status={true}
                            isIcon={true}
                            img={AllIcons.tickIcon}
                            onClick={() => {
                                setOpenModal(false);
                                RedirectToExam()
                            }}
                            buttonType="proceed-btn"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ExamStartModal;
